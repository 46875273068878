import React from 'react'
import {
  ImageContainer,
  StyledImage,
  Shine,
  PhotoShadow,
  Container,
} from './FancyGiftCard.styled'

export class FancyGiftCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      intensityX: 0.05,
      intensityY: 0.1,
      nearShadow: 0.7,
      farShadowX: 0.85,
      farShadowY: 0.75,
      lastGyro: 0,
      touchDevice: false,
    }
  }

  // orientationEventListner = event => {
  //   this.tilt([event.beta, event.gamma])
  // }

  componentDidMount() {
    if (!window || !this.containerComp) return

    window.addEventListener('touchstart', () => {
      this.setState({ touchDevice: true })
    })

    this.containerComp.addEventListener('mousemove', this.handleMousemove)
    this.containerComp.addEventListener('mouseout', this.handleMouseout)

    this.setState({
      bodyRect: document.body.getBoundingClientRect(),
      cardRect: this.containerComp.getBoundingClientRect(),
    })

    //ios Gyroscope:
    // if (window.DeviceOrientationEvent) {
    //     window.addEventListener("deviceorientation", this.orientationEventListner);
    // }
  }

  componentWillUnmount() {
    if (!window || !this.containerComp) return
    this.containerComp.removeEventListener('mousemove', this.handleMousemove)
  }

  handleMouseout = event => {
    const { nearShadow } = this.state
    this.imageComp.style.transform = 'none'
    this.shadowComp.style.transform = `scale(${nearShadow})`
  }

  handleMousemove = event => {
    const {
      cardRect,
      intensityX,
      intensityY,
      farShadowX,
      farShadowY,
      touchDevice,
    } = this.state

    if (touchDevice) {
      return
    }

    const mouseX = event.offsetX
    const mouseY = event.offsetY

    //rotate
    var rx = -((mouseY - this.containerComp.offsetHeight / 2) * intensityY)
    var ry = (mouseX - this.containerComp.offsetWidth / 2) * intensityX

    var transform = `rotateX(${rx}deg) rotateY(${ry}deg)`

    //shine
    var dy = mouseY - cardRect.height / 2
    var dx = mouseX - cardRect.width / 2
    var theta = Math.atan2(dy, dx)
    var shineAngle = (theta * 180) / Math.PI - 90 //convert rad in degrees
    if (shineAngle < 0) {
      shineAngle = shineAngle + 360
    }

    //shadow
    var shadowStrength = 20
    var shadX = this.containerComp.offsetWidth / shadowStrength
    var shadY = this.containerComp.offsetHeight / shadowStrength

    var sx = -((mouseX - this.containerComp.offsetHeight / 2) / shadX)
    var sy = -((mouseY - this.containerComp.offsetWidth / 2) / shadY)
    var shadow = `translateX(${sx}px) translateY(${sy}px) translateZ(-1rem) scale(${farShadowX}, ${farShadowY})`

    this.imageComp.style.transform = transform
    this.shadowComp.style.transform = shadow
    this.shineComp.style.background =
      'linear-gradient(' +
      shineAngle +
      'deg, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 75%)'
  }

  render() {
    const { nearShadow } = this.state
    return (
      <Container innerRef={comp => (this.containerComp = comp)}>
        <ImageContainer innerRef={comp => (this.imageComp = comp)}>
          <PhotoShadow
            innerRef={comp => (this.shadowComp = comp)}
            nearShadow={nearShadow}
          />
          <Shine innerRef={comp => (this.shineComp = comp)} />
          <StyledImage {...this.props} />
        </ImageContainer>
      </Container>
    )
  }
}
