import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { get } from 'lodash'

import { Layout } from 'src/components/Layout/Layout'
import { MediumContentContainer } from 'src/components/Layout/Structure'
import { CreamBox } from 'src/components/Shared/CreamBox/CreamBox'
import { Button } from 'src/components/Shared/Button/Button'
import { Content, StyledFancyGiftCard } from './_gift-cards.styled'

export const GiftCardsPage = ({ data }) => {
  const page = get(data, 'prismicGiftCardsPage')
  const giftCardImageFluid = get(page, 'data.gift_card_image.fluid')

  return (
    <Layout activePageName="Gift Cards">
      <Helmet title={get(page, 'data.title.text')} />
      <CreamBox>
        <MediumContentContainer>
          <Content>
            {giftCardImageFluid && (
              <StyledFancyGiftCard fluid={giftCardImageFluid} />
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: get(page, 'data.intro.html'),
              }}
            />
            <Button to="https://shop.kalapawaimarket.com/products/gift-cards">
              Shop Gift Cards
            </Button>
          </Content>
        </MediumContentContainer>
      </CreamBox>
    </Layout>
  )
}

export default GiftCardsPage

export const query = graphql`
  query {
    prismicGiftCardsPage {
      data {
        title {
          text
        }
        intro {
          html
        }
        gift_card_image {
          alt
          fluid(maxWidth: 1000) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`
