import styled from 'react-emotion'

import t from 'src/theme'
import { Image } from 'src/components/Shared/Image'

export const ImageContainer = styled.div`
  //pointer was interfering with the container events
  pointer-events: none;
  touch-action: none;
`

export const StyledImage = styled(Image)`
  border-radius: 14px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
`

export const Shine = styled.div`
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  z-index: 100;
`

export const PhotoShadow = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.9);
  transform: scale(${p => p.nearShadow});
  transition: transform calc(${t.t} / 1.5);
`

export const Container = styled.div`
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  max-width: ${t.ms.t};
  margin: 0 auto;
  transition: transform ${t.t};
  perspective: 1000px;
  transform: translate3d(0, 0, 0);
  ${t.mq.s} {
    &:hover {
      transform: scale(1.1);
      transition: transform ${t.t};
    }
  }
`
