import styled from 'react-emotion'

import t from 'src/theme'
import { FancyGiftCard } from 'src/components/Shared/FancyGiftCard/FancyGiftCard'

export const Content = styled.div`
  text-align: center;

  p {
    margin-bottom: ${t.s(3)};
  }
`

export const StyledFancyGiftCard = styled(FancyGiftCard)`
  margin-bottom: ${t.s(3)};
`
